import { createContainer } from "unstated-next";

const useContainer = (initialState = "") => {
  return {
    variant: initialState,
  };
};

const { useContainer: useExperiment, Provider: ExperimentProvider } =
  createContainer(useContainer);

export { ExperimentProvider, useExperiment };
