import { useEffect, useState } from "react";

// Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    // eslint-disable-next-line no-undefined
    width: undefined,
    // eslint-disable-next-line no-undefined
    height: undefined,
  });

  function handleResize() {
    // Set window width/height to state
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export const useBreakpoint = () => {
  const { width } = useWindowSize();
  if (width < 640) {
    return "sm";
  } else if (width < 768) {
    return "md";
  } else if (width < 1024) {
    return "lg";
  } else {
    return "xl";
  }
};

export const useOrientation = () => {
  const { width, height } = useWindowSize();

  return {
    landscape: height < width,
    portrait: height > width,
  };
};

export const useIsMobile = () => {
  const breakpoint = useBreakpoint();
  return ["sm", "md", "lg"].includes(breakpoint);
};

export const useIsDesktop = () => {
  const breakpoint = useBreakpoint();
  return ["xl"].includes(breakpoint);
};
