const whiteList = [
  "3ab2dc4a-0a91-4041-8479-e5e01999a60e", // corey
  "bcd903f7-ecba-4184-9ddd-0a2cbd442780", // maia
  "3f38dc60-a175-465d-972e-ae6579a22f49", // nicole
  "fe1d175a-0104-46d0-8a4a-cea42ecfe5e5", // andre
  "fe2cd695-af36-4d6e-ba51-645645fbce17", // sam t
  "612b34d6-8e49-4b0c-a5ed-ab79c8101169", // elise
  "614035e3-1d63-44a2-95bb-ae63fab9d63e", // samantha
];
export const whiteLists = {
  voInspo: whiteList,
  chatInsights: whiteList,
};

export const canAccessVoInspoCheck = (
  isAdmin: boolean,
  isCoach,
  isCoachesTeam,
  userID: string
) => {
  if (isAdmin) return true;

  // const isAllowedAccess = whiteLists.voInspo.includes(userID);
  const isActiveCoach = isCoach && isCoachesTeam;
  return isActiveCoach;
};

export const canAccessChatInsightsCheck = (isAdmin: boolean, userID: string) =>
  isAdmin || whiteLists.chatInsights.includes(userID);
