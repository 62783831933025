export const fetcher = async (...args) => {
  const response = await fetch(...args);

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(
      errorMessage || `Request failed with status code ${response.status}`
    );
  }

  return response.json();
};
