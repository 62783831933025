import { canAccessVoInspoCheck } from "apollo/constants";
import findIndex from "lodash/findIndex";
import { useCurrentUser } from "queries/current-user";
import { MovementComponent } from "queries/workout/types";

import { useEffect, useRef, useState } from "react";
import { useWorkout } from "ui/lib/useWorkout";
import { createContainer } from "unstated-next";

const useContainer = () => {
  const { isAdmin, isCoach, isCoachesTeam, currentUser } = useCurrentUser();
  const { data: remoteData, isLoading } = useWorkout();
  const [activeId, setActiveId] = useState("");
  const [activeItem, setActiveItem] = useState<Partial<MovementComponent>>({});
  const [nextItem, setNextItem] = useState({});
  const [previousItem, setPreviousItem] = useState({});
  const [previousId, setPreviousId] = useState<string | undefined>();
  const [nextId, setNextId] = useState<string | undefined>();
  const [playlistDictionaryData, setPlaylistDictionaryData] = useState({});
  const onProgramPlaylistIdChange = useRef<{
    id?: string;
    event?: () => null;
  }>({});

  const canAccessVoInspo = canAccessVoInspoCheck(
    isAdmin,
    isCoach,
    isCoachesTeam,
    currentUser?.id || ""
  );

  const [aiOpen, setAiOPen] = useState(() => canAccessVoInspo);

  const handleNext = (e) => {
    if (e?.defaultPrevented) return;
    if (document?.activeElement?.nodeName === "INPUT") return;
    activeId && nextId && setActiveId(nextId);
  };

  const handlePrevious = (e) => {
    if (e?.defaultPrevented) return;
    if (document?.activeElement?.nodeName === "INPUT") return;
    activeId && previousId && setActiveId(previousId);
  };

  const setProgramPlaylistIdChangeEvent = (id?: string, event?: any) => {
    onProgramPlaylistIdChange.current = {
      id,
      event,
    };
  };

  useEffect(() => {
    if (typeof onProgramPlaylistIdChange.current?.event === "function") {
      console.log("calling onProgramPlaylistIdChange");
      onProgramPlaylistIdChange.current.event();
      setProgramPlaylistIdChangeEvent();
    }
  }, [activeId, previousId, nextId]);

  useEffect(() => {
    if (!!activeId) {
      setActiveItem(playlistDictionaryData?.[activeId]);
    }
    if (!!nextId) {
      setNextItem(playlistDictionaryData?.[nextId]);
    }
    if (!!previousId) {
      setPreviousItem(playlistDictionaryData?.[previousId]);
    }
  }, [activeId, previousId, nextId, playlistDictionaryData]);

  useEffect(() => {
    if (!activeId) return;
    if (!remoteData) return;
    const { playlist } = remoteData;
    const currentIndex = findIndex(playlist, ["id", activeId]);

    setNextId(playlist[currentIndex + 1]?.id);
    setPreviousId(playlist[currentIndex - 1]?.id);
  }, [activeId, remoteData]);

  useEffect(() => {
    if (!remoteData) return;
    if (!remoteData.playlist) return;
    const dict = {};
    remoteData.playlist.map((playlistItem) => {
      dict[playlistItem.id] = playlistItem;
    });
    setPlaylistDictionaryData(dict);
  }, [remoteData, isLoading]);

  return {
    setActiveId,
    activeItem,
    activeId,
    handlePrevious,
    handleNext,
    previousId,
    nextId,
    previousItem,
    nextItem,
    aiOpen,
    setAiOPen,
    playlist: remoteData?.playlist,
    playlistDictionaryData,
    onProgramPlaylistIdChange,
    setProgramPlaylistIdChangeEvent,
  };
};

export const {
  useContainer: useProgramPlaylist,
  Provider: ProgramPlaylistProvider,
} = createContainer(useContainer);
