import * as Sentry from "@sentry/nextjs";
import { useProgramPlaylist } from "apollo/containers/program-playlist";
import debounce from "lodash/debounce";

import { useEffect, useRef, useState } from "react";

export function useOutsideAlerter(ref, handleClick = () => null, props = []) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClick(event);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ...props]);
}

export const useEventListener = (event = "", action, props = [], bubble) => {
  useEffect(() => {
    if (!process.browser) return;
    document.addEventListener(event, action, bubble);
    return () => document.removeEventListener(event, action, bubble);
  }, props);
};

export const useWindowEventListener = (event = "", action, props = []) => {
  useEffect(() => {
    if (!process.browser) return;
    window.addEventListener(event, action);
    return () => window.removeEventListener(event, action);
  }, props);
};

export const useKeyUp = (keyCode, action, props) => {
  useEventListener(
    "keyup",
    (e) => {
      if (["INPUT", "TEXTAREA"].includes(e.target.nodeName)) return;
      if (e.keyCode !== keyCode) return;
      action(e, props);
    },
    props
  );
};

export const useKeyDown = (keyCode, action, props) => {
  useEventListener(
    "keydown",
    (e) => {
      if (["INPUT", "TEXTAREA"].includes(e.target.nodeName)) return;
      if (e.keyCode !== keyCode) return;
      action(e, props);
    },
    props
  );
};

export const useEscapeKey = (action, props = []) => {
  useKeyUp(27, action, props);
};

export const useMouseDown = (props = []) => {
  const [mouseDown, setMouseDown] = useState(false);
  let timeout;

  const handleAction = () => setMouseDown(true);

  const handleMouseDown = () => {
    timeout = setTimeout(handleAction, 250);
  };
  const handleMouseUp = () => {
    clearTimeout(timeout);
    setMouseDown(false);
  };

  useEventListener("mousedown", handleMouseDown, [
    ...props,
    mouseDown,
    setMouseDown,
  ]);
  useEventListener("mouseup", handleMouseUp, [
    ...props,
    mouseDown,
    setMouseDown,
  ]);

  return mouseDown;
};

export const useHeldKey = (keyCode, props = []) => {
  const [isHeldDown, setIsHeldDown] = useState(false);
  let timeout;

  const handleAction = () => {
    // if (document?.activeElement?.nodeName === "INPUT") return;
    setIsHeldDown(true);
  };

  const handleKeyDown = () => handleAction();
  const handleKeyUp = () => {
    clearTimeout(timeout);
    setIsHeldDown(false);
  };

  useKeyDown(keyCode, handleKeyDown, [
    ...props,
    timeout,
    isHeldDown,
    setIsHeldDown,
  ]);
  useKeyUp(keyCode, handleKeyUp, [
    ...props,
    timeout,
    isHeldDown,
    setIsHeldDown,
  ]);

  useEffect(() => {
    window.addEventListener("blur", handleKeyUp);
    return () => window.removeEventListener("blur", handleKeyUp);
  }, [...props, isHeldDown, setIsHeldDown, timeout]);

  useEffect(() => {
    return () => timeout && clearTimeout(timeout);
  }, []);

  return isHeldDown;
};

export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [hasAccessedLocalstorage, setHasAccess] = useState(false);
  const [storedValue, setStoredValue] = useState(initialValue);

  const setValueFromLocalStorage = () => {
    try {
      if (!process.browser) return initialValue;
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      setHasAccess(true);
      // Parse stored json or if none return initialValue
      return item
        ? typeof item === "string"
          ? item
          : JSON.parse(item)
        : initialValue;
    } catch (error) {
      // If error also return initialValue
      Sentry.captureException(error);
      return initialValue;
    }
  };
  useEffect(
    () => void setStoredValue(setValueFromLocalStorage()),
    [process.browser, key]
  );

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(
        key,
        typeof valueToStore === "string"
          ? valueToStore
          : JSON.stringify(valueToStore)
      );
    } catch (error) {
      // A more advanced implementation would handle the error case
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    if (!!initialValue && typeof initialValue === "string") {
      setValue(initialValue);
    }
  }, [initialValue]);

  return [storedValue, setValue, hasAccessedLocalstorage];
};

export const useIsMounted = (delay = 0) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setMounted(true), delay);
    return () => {
      setMounted(false);
      clearTimeout(timeout);
    };
  }, []);
  return mounted;
};

export function useDebounce(value, delay, key) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  let handler;

  useEffect(
    () => {
      // Update debounced value after delay
      handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay, key] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export function useWindowResize(action, props) {
  useWindowEventListener("resize", action, props);
}

export const useWindowSize = (useResize = true) => {
  let [windowSize, setWindowSize] = useState({
    innerHeight: window?.innerHeight,
    innerWidth: window?.innerWidth,
    outerHeight: window?.outerHeight,
    outerWidth: window?.outerWidth,
  });

  function getSize() {
    if (!window) {
      return {};
    }
    return {
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      outerHeight: window.outerHeight,
      outerWidth: window.outerWidth,
    };
  }

  function handleResize() {
    if (!useResize) return;
    setWindowSize(getSize());
  }

  useEffect(() => {
    setWindowSize(getSize());
    window.addEventListener("resize", debounce(handleResize, 250));
    return () => {
      window.removeEventListener("resize", debounce(handleResize, 500));
    };
  }, []);

  return windowSize;
};

export const useBreakPoints = ({
  defaultValue = false,
  useResize = true,
} = {}) => {
  const defaultValues = {
    sm: defaultValue,
    md: defaultValue,
    lg: defaultValue,
    xl: defaultValue,
  };
  const breakpoints = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  };
  const { innerWidth } = useWindowSize(useResize);

  const [current, setCurrent] = useState(defaultValues);

  useEffect(() => {
    setCurrent(() => {
      const newValues = {};
      Object.keys(breakpoints).map((breakpoint) => {
        const val = breakpoints[breakpoint];
        newValues[breakpoint] = innerWidth >= val;
      });

      return newValues;
    });
  }, [innerWidth]);

  return current;
};

export const useActiveItemInPlaylist = ({
  activeType,
  activeId,
  currentId,
  setActive,
  type,
}) => {
  useEffect(() => {
    if (!activeId) return;

    if (activeId === currentId && activeType === type) {
      setActive(true);
    }
    if (activeId !== currentId) {
      setActive(false);
    }
  }, [activeId]);
};

export function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef(null);

  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });

      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }

    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

export const useShiftIsHeld = () => useHeldKey(16);

export const usePlaylistActivator = ({
  id,
  currentlyActive,
  handleActivation,
}) => {
  const { setActiveId, activeId } = useProgramPlaylist();
  useEffect(() => {
    if (id) return;

    if (id === activeId && !currentlyActive) {
      handleActivation();
    }
    if (id !== activeId && currentlyActive) {
      setActiveId(id);
    }
  }, [id, activeId, currentlyActive]);
};

export const usePlaylistKeyJumper = ({ activeMediaModalId }) => {
  const { handlePrevious, handleNext, previousId, nextId } =
    useProgramPlaylist();

  //up
  useKeyUp(38, handlePrevious, [
    activeMediaModalId,
    handlePrevious,
    previousId,
  ]);

  //down
  useKeyUp(40, handleNext, [activeMediaModalId, handleNext, nextId]);

  //left
  useKeyUp(37, handlePrevious, [
    activeMediaModalId,
    handlePrevious,
    previousId,
  ]);

  //right
  useKeyUp(39, handleNext, [activeMediaModalId, handleNext, nextId]);
};
