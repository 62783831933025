import { useEffect } from "react";
import { fetcher } from "./fetcher";

export { fetcher };

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function useOutsideAlerter(ref, handleClick = () => null, props = []) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClick(event);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, ...props]);
}

export function getUrlWithUtms(urlString = "", utmContentAppend = "") {
  // Return early if we're not in a browser environment
  if (typeof window === "undefined") return urlString;

  let url;

  try {
    // If urlString is already a URL object, use it directly
    if (urlString instanceof URL) {
      url = urlString;
    } else {
      // If urlString is empty or invalid, use the current page URL
      url = new URL(urlString || window.location.href);
    }
  } catch (error) {
    console.error("Invalid URL:", urlString);
    return urlString; // Return the original input if it's invalid
  }

  const params = new URLSearchParams(window.location.search);
  const keys = [
    "utm_source",
    "utm_medium",
    "utm_term",
    "utm_campaign",
    "utm_content",
    "savvy_flow_version",
    "promoCode",
    "promoOriginatorUserID",
    "promoSigToken",
    "variant",
  ];

  keys.forEach((key) => {
    const value = params.get(key);
    if (key === "utm_content" && params.get("workoutId")) {
      url.searchParams.set(
        key,
        `web_wo_${params.get("workoutId")}${utmContentAppend}`
      );
    } else if (value) {
      url.searchParams.set(key, value);
    }
  });

  return url;
}

export const updateUTMParams = (initialURL, utmContentAppend) => {
  let url;
  if (!initialURL) return initialURL;

  try {
    url = new URL(initialURL);
  } catch (_) {
    if ("undefined" !== typeof window) {
      url = new URL(window.location.origin + initialURL);
    }
  }
  return getUrlWithUtms(url, utmContentAppend);
};

export function createDictionary(data, key = "id") {
  if (!data?.length) return {};

  const dictionary = {};
  data.map((n) => {
    dictionary[n[key]] = n;
  });

  return dictionary;
}

export function useLockBodyScroll() {
  useEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    document.body.style.overflow = "hidden";
    // Re-enable scrolling when component unmounts
    return () => (document.body.style.overflow = originalStyle);
  }, []); // Empty array ensures effect is only run on mount and unmount
}
