import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";
import { useState } from "react";
import useRequest, { fetcher } from "./useRequest";

export const useWorkoutByID = (workoutID, config = {}) => {
  const response = useRequest(workoutID && `workouts/${workoutID}`, config);
  return response;
};

export const useWorkout = (config = {}) => {
  const { query } = useRouter();
  const [errors, setErrorsState] = useState({});
  const { data, error, mutate, revalidate, isValidating } = useWorkoutByID(
    query.workoutId,
    config
  );

  const deleteWorkout = async () => {
    try {
      await fetcher.delete(`workouts/${query.workoutId}`);
    } catch (error) {
      setErrorsState((c) => ({
        ...c,
        [query.workoutId]: "Something went wrong",
      }));
      Sentry.captureException(error);
    }
  };

  const sectionState = data?.sectionState;

  return {
    sectionState,
    deleteWorkout,
    data,
    error,
    errors,
    mutate,
    revalidate,
    isValidating: (!data || data?.id !== query.workoutId) && isValidating,
  };
};
